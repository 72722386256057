import React from 'react';

import CustomizeIcon from '../../assets/customize-icon.png';
import InteractiveIcon from  "../../assets/interactive-icon.png";
import IntegrationIcon from '../../assets/integration-icon.png';
import AnalyticsIcon from "../../assets/analytics-icon.png";

import './styles/feature-section.css';

const FeatureSection = () => {
    return (
        <div className="feature1" id="feature-section">
            <div className="features-container">
                <h2 className="features-title">Our Features</h2>
                <div className='grid_content'>
                    <div className="features-grid">
                        <div className="feature-card">

                            <img src={CustomizeIcon} alt="" />
                            <div className="feature-content">
                                <h3>Customizable Templates</h3>
                                <p>Create beautiful, customized email templates to match your brand and campaign goals.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                            <img src={InteractiveIcon} alt="" />
                            <div className="feature-content">
                                <h3>Interactive Emails</h3>
                                <p>Engage your customers with interactive emails that allow them to view and purchase products directly from their inbox.</p>
                            </div>
                        </div>
                        <div className="feature-card">

                            <img src={IntegrationIcon} alt="" />
                            <div className="feature-content">
                                <h3>Easy Integration</h3>
                                <p>Integrate our platform seamlessly with your existing marketing tools and workflows.</p>
                            </div>
                        </div>
                        <div className="feature-card">

                            <img src={AnalyticsIcon} alt="" />
                            <div className="feature-content">
                                <h3>Analytics & Reporting</h3>
                                <p>Gain insights into your email campaigns with our comprehensive analytics and reporting tools.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureSection;