import React from 'react';
import "./styles/pricing-table.css"

function PricingTable() {
  const plans = [
    {
      name: 'Basic',
      price: '5000/month',
      emails: '25000 emails',
      features: [
        { text: 'Unlimited Contacts', included: true },
        { text: 'Assisted onboarding', included: true },
        { text: 'Product Feedbacks', included: false },
        { text: 'Custom Templates', included: false },
      ],
    },
    {
      name: 'Standard',
      price: '7500/month',
      emails: '40000 emails',
      features: [
        { text: 'Unlimited Contacts', included: true },
        { text: 'Assisted onboarding', included: true },
        { text: 'Product Feedbacks', included: true },
        { text: 'Custom Templates', included: false },
      ],
    },
    {
      name: 'Premium',
      price: '12000/month',
      emails: '65000 emails',
      features: [
        { text: 'Unlimited Contacts', included: true },
        { text: 'Assisted onboarding', included: true },
        { text: 'Product Feedbacks', included: true },
        { text: 'Custom Templates', included: true },
      ],
    },
  ];

  return (
    <div className="flex-container">
      <div className="grid-container">
        {plans.map((plan, index) => (
          <div
            key={plan.name}
            className={`plan-box ${
              index === 0
                ? 'first-plan' // Left box or top rounded on mobile
                : index === 1
                ? '' // Middle box
                : 'last-plan' // Right box or bottom rounded on mobile
            }`}
          >
            <h2 className="plan-title">
              {plan.name}
            </h2>
            <p className="plan-price">
              {plan.price}
            </p>
            <p className="plan-emails">
              {plan.emails}
            </p>
            <ul className="custom-list">
              {plan.features.map((feature, index) => (
                <li key={index} >
                  {feature.included ? (
                    <span className="check-mark">&#10003;</span> // Checkmark
                  ) : (
                    <span className="cross-mark">&#10007;</span> // Cross
                  )}
                  <span className="feature-text">{feature.text}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PricingTable;
