import React, { useState } from 'react';
import Logo from '../assets/logo.png';

import FormSection1 from "../components/signup-page/SignupFormSection1";
import FormSection2 from  "../components/signup-page/SignupFormSection2";
import FormSection3 from '../components/signup-page/SignupFormSection3';
import FormSection4 from "../components/signup-page/SignupFormSection4";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer"

import "./styles/signup.css";

function Signup() {
	const [step, setStep] = useState(1); // Assuming starting at step 2
	const [formData, setFormData] = useState({
		email: '',
		firstname: '',
		lastname: '',
		password: '',
		brandname: '',
		brandurl: '',
		brandmail: '',
		brandwebsite: '',
		brandsupportmail: '',
		brandlogo: '',
		
		confirmPassword: '',
	});

	const handleFormDataChange = (newData) => {
		setFormData((prevData) => ({ ...prevData, ...newData }));
	};

	return (
		<>
			<div className="signup-container">
				<Navbar />

				<div className="signup-row-container">
					<div className='signup-brand-container'>
						<div className='signup-title'>The Bommber</div>
						<div className='subtitle'>Launch.Engage.Impact</div>
						
						{/* Show logo only on large screens */}
						<img 
							src={Logo}
							alt="The Bommber Logo" 
							className="signup-logo-image" 
						/>
					</div>

					<div className="signup-form-container">
						 {step === 1 && <FormSection1 setStep={setStep} 
								formData={formData}
								handleFormDataChange={handleFormDataChange}/>}
						{step === 2 && <FormSection2 setStep={setStep} 
									formData={formData} 
									handleFormDataChange={handleFormDataChange}/>}
						{step === 3 && <FormSection3 setStep={setStep} 
									formData={formData}
									handleFormDataChange={handleFormDataChange}/>}
						{step === 4 && <FormSection4 setStep={setStep} 
									formData={formData} 
									handleFormDataChange={handleFormDataChange}/>}
					</div>
				</div>
			</div>
			<Footer/>
		</>
	);
}

export default Signup;
