import React from 'react';
import './styles/phone-frame.css'; // Import a CSS file for styling
import WifiIcon from '../../assets/wifi-icon.png';
import NetworkIcon from '../../assets/network-icon.png';
import BatteryIcon from '../../assets/battery-icon.png';

const PhoneFrame = (props) => {
  return (
    <div className="phone-container">
		<div className="phone-content">
			<div className="phone-header">
				<div className="time">9:41</div>
				<div className="dynamic-island"></div>
				<div className="status-icons">
					<img src={NetworkIcon} alt="Network Icon" />
					<img src={WifiIcon} alt="Wifi Icon" />
					<img src={BatteryIcon} alt="Battery Icon" />
				</div>
			</div>
			<div className="screen-content">
				{props.children}
			</div>
		</div>
    </div>
  );
};

export default PhoneFrame;
