import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import CryingBee from '../assets/crying-bee-error-404.png'

import "./styles/error-404.css"

const Error404 = () => {
	return (
		<>
			<Navbar />
			<div className="content-404">

				<div className="text-section">
					<div className="page-title">LOOKS LIKE YOU'RE LOST..</div>
					<div className="page-content">Don't Worry, sometimes we're lost only to be found again and treasured</div>

					<div className="button-text">We got you</div>
					<button><Link to="/" style={{textDecoration:'none'}}>..take me home</Link></button>
				</div>

				<div className="image-section">
					<img src={CryingBee} alt="Crying Bee" ></img>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Error404;