import React from "react";
import { ChevronLeft, Archive, Trash2, Mail, Ellipsis  } from 'lucide-react'

import './styles/mail-header.css'

const MailHeader = () => {
	return (
		<div className="mail-header">
			< div className="back-button">
				<ChevronLeft size={16} color="#7a7a7a" />	
			</div>

			<div className="other-icons">
				<Archive style={{margin:"0 8px"}} size={16} color="#7a7a7a"/>
				<Trash2 style={{margin:"0 8px"}} size={16} color="#7a7a7a" />
				<Mail style={{margin:"0 8px"}} size={16} color="#7a7a7a" />
				<Ellipsis style={{margin:"0 8px"}} size={16} color="#7a7a7a" />
			</div>
		</div>
	)
}

export default MailHeader;