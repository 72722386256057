import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PricingTable from '../components/pricing-page/PricingTable';
import './styles/pricing.css'

function Pricing() {
  const navigate = useNavigate();

  // Handle the button click
  const handleTrialButtonClick = () => {
    navigate('/signup');
  };

  return (
	<>
		<div className="page-container">
		<Navbar />

			{/* Background Circles */}
			<div className="circle-styles yellow-circle-styles"></div>
			<div className="circle-styles red-circle-styles"></div>
			<div className="circle-styles blue-circle-styles"></div>

			{/* Content Section with responsive text */}
			<div className="pricing-text-container">
				<p className="responsive-text">
				Explore our flexible and affordable pricing plans, tailored to fit businesses of all sizes. Whether you're a startup or an enterprise,
				The Bommbers offers transparent, scalable options that align with your budget and goals.
				Each plan is packed with essential features to elevate your email marketing and boost engagement.
				Find the perfect plan below and take your email marketing to new heights with The Bommbers today.
				</p>
			</div>

			{/* Pricing Table */}
			<PricingTable />

			{/* Button to Start 14-day Trial */}
			<div className="free-trial-button-container">
				<button
				onClick={handleTrialButtonClick}
				className="free-trial-button"
				>
				Start your 14-day trial now
				</button>
			</div>
		</div>

		<Footer />
	</>
  );
}

export default Pricing;
