import React from 'react';
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import './styles/shipping-and-delivery.css';

const ShippingAndDelivery = () => {
    return (
		<>
			<Navbar />
			<div className="container">	
				<div className="page-title">Shipping and Delivery</div>

				<div className="shipping-and-delivery-content">
					<p className="last-updated">Last updated: July 21, 2024</p>
					<p>Shipping is not applicable for business.</p>
				</div>			
			</div>
			<Footer />
		</>
    );
};

export default ShippingAndDelivery;
