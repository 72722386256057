import React, { useState } from 'react';
import SellTemplate1 from '../mail-templates/SellTemplate1';
import JobApplicationTemplate from '../mail-templates/JobApplicationTemplate';
import SellTemplate2 from '../mail-templates/SellTemplate2';
import QuizTemplate from '../mail-templates/QuizTemplate';
import PrelaunchTemplate from '../mail-templates/PrelaunchTemplate';

import { ChevronLeft, ChevronRight } from 'lucide-react';

import './styles/template-carousel.css';

const TemplatesCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(2); // Start with the third card as the center
    const [isAnimating, setIsAnimating] = useState(false); // To handle animation

    const cards = [<JobApplicationTemplate />, <SellTemplate2 />, <SellTemplate1 />, <QuizTemplate />, <PrelaunchTemplate />];

    const goLeft = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? cards.length - 1 : prevIndex - 1));
            setIsAnimating(false);
        }, 300); // Animation duration should match the CSS transition duration
    };

    const goRight = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
            setIsAnimating(false);
        }, 300); // Animation duration should match the CSS transition duration
    };

    const getCard = (indexOffset) => {
        const newIndex = (currentIndex + indexOffset + cards.length) % cards.length;
        return (
			cards[newIndex]
        );
    };

    return (
        <div className="carousel-container">
            <button className="carousel-btn left" onClick={goLeft} disabled={isAnimating}><ChevronLeft className='arrow-buttons' strokeWidth={3} color="#ffffff"  /></button>
            <div className="carousel">
                <div className="card blur" >{getCard(-2)}</div>
                <div className="card blur1">{getCard(-1)}</div>
                <div className={`card active ${isAnimating ? 'fade' : ''}`}>{getCard(0)}</div> {/* Center card */}
                <div className="card blur1">{getCard(1)}</div>
                <div className="card blur">{getCard(2)}</div>
            </div>
            <button className="carousel-btn right" onClick={goRight} disabled={isAnimating}><ChevronRight className='arrow-buttons' strokeWidth={3} color="#ffffff" /></button>
        </div>
    );
};

export default TemplatesCarousel;
