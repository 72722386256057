import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ChevronLeft } from 'lucide-react';

import "./styles/signup-form-section4.css";

function SignupFormSection4({ setStep, formData, handleFormDataChange }) {
	const [errorMessage, setErrorMessage] = useState("");
	const [inProp, setInProp] = useState(true);

	function handleChange(e){
		const { id, value } = e.target;
		handleFormDataChange({
			[id]: value,
		});
	};

	function isValidPassword(password) {
		// Regular expression to check the password requirements
		const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
	
		// Test the password against the regular expression
		return regex.test(password);
	}

	function validateInput(){
		if (!formData.password){
			setErrorMessage('Password is required');
			return false;
		}

		if( !isValidPassword(formData.password) ){
			setErrorMessage("Enter a valid password. Password must contain one uppercase character, one lowercase character, one number and one special character");
			return false;
		}

		if (!formData.confirmPassword){
			setErrorMessage('Confirm Password is required');
			return false;
		}

		if (formData.password !== formData.confirmPassword){
			setErrorMessage('Passwords do not match');
			return false;
		}
		return true;
	};

	async function handleNext(e){
		e.preventDefault(); // Prevent the default form submission
		if (validateInput()) {
			await fetch('/api/signup/newuser', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			})
			.then(response=>response.json())
			.then(response=>{
				console.log(response)
				if( response.success ){
					window.location.href = "/campaigns.html"
				}else {
					setErrorMessage(response.message)
				}
			})
			.catch(error => {
				alert('Error occurred during signup');
				console.log(error);
			});
		}
	};

	function handleBack(){
		setInProp(false); // Start the exit transition
		setTimeout(() => {
			setStep(3); // Go back to step 3
		}, 600);
	};

	return (
		<CSSTransition
			in={inProp}
			timeout={600} // Transition duration in milliseconds
			classNames="flip"
			unmountOnExit
		>
			<div className="signup-form-section4"> 
				<div className="signup-form-section4-card">
					<h2 className="signup-form-section4-heading">Create Password</h2>

					<div className="form-section-row-container">
						{/* Back Arrow Button */}
						<ChevronLeft size={36} onClick={handleBack}/>

						<form  onSubmit={handleNext}>
							<div className='form-subsection'>
								<label htmlFor="password">Password</label>
								<input
									type="password"
									id="password"
									value={formData.password}
									onChange={handleChange}
								/>
							</div>

							<div className='form-subsection'>
								<label htmlFor="confirmPassword">Confirm Password</label>
								<input
									type="password"
									id="confirmPassword"
									value={formData.confirmPassword}
									onChange={handleChange}
								/>
							</div>

							<p className="error-message">{errorMessage}</p>

							<div className="form-step">4/4</div>

							<button type="submit">Sign Up</button>
						</form>
					</div>

					<p className="signup-text">
						Already have an account?<a href="/login">Login</a>
					</p>
				</div>
			</div>
		</CSSTransition>
	);
}

export default SignupFormSection4;
