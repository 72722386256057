import React from "react";
import Logo from '../../assets/logo.png'

import './styles/bommber-offering.css';

const BommberOffering = () => {
    return (
        <div className="bommber-offering-container">
            <div className="content">
                <h1 className="title">
                    Revolutionary Email Purchasing: <br />
                    Buy Products Directly from Your Inbox
                </h1>
                <div className="features">
                    <div className="feature">
                        <h2>Enhanced Efficiency</h2>
                        <p>
                            Save time and effort by completing your purchases without
                            navigating away from your emails.
                        </p>
                    </div>
                    <div className="feature">
                        <h2>Simplified Shopping</h2>
                        <p>
                            Browse, select, and purchase products seamlessly without ever
                            leaving your email inbox.
                        </p>
                    </div>
                </div>
            </div>
            <div className="image-placeholder">
                <img src={Logo} alt="Product Character" />
            </div>
        </div>
    );
};

export default BommberOffering;
