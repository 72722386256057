import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ChevronLeft } from 'lucide-react';

import "./styles/signup-form-section3.css";

function SignupFormSection3({ setStep, formData, handleFormDataChange }) {
	const [errorMessage, setErrorMessage] = useState("");
	const [inProp, setInProp] = useState(true);

	// Setting Brand Mail
	// formData.brandmail = formData.brandname.replace(/\s+/g, '').toLowerCase() + "@thebommber.com";

	function handleChange(e) {
		const { id, value } = e.target;
		handleFormDataChange({
			[id]: value,
		});
	};

	function validateInput(){
		if (!formData.brandurl){
			setErrorMessage('Privacy Policy URL is required');
			return false;
		}

		if (!formData.brandsupportmail){
			setErrorMessage('Support Mail Address is required');
			return false;
		}

		if (!formData.brandmail){
			setErrorMessage('Brand Mail is required');
			return false;
		}

		if( !formData.brandmail.endsWith("@thebommber.com") ){
			setErrorMessage('Brand Mail must end with @thebommber.com')
			return false;
		}
		
		return true;
	};

	function handleNext(e){
		e.preventDefault();
		if (validateInput()) {
				setInProp(false);
				setTimeout(() => {
				setStep(4); // Proceed to step 4
			}, 600); // Navigate after the animation
		}
	};

	function handleBack() {
		setInProp(false); // Start the exit transition
		setTimeout(() => {
			setStep(2); // Go back to step 2
		}, 600);
	};

	return (
		<CSSTransition
			in={inProp}
			timeout={600} // Transition duration in milliseconds
			classNames="flip"
			unmountOnExit
		>
			<div className="signup-form-section3"> 
			<div className="signup-form-section3-card">
				<h2 className="signup-form-section3-heading">Sign Up</h2>

				<div className="form-section-row-container">
					{/* Back Arrow Button */}
					<ChevronLeft size={36} onClick={handleBack}/>

					<form onSubmit={handleNext}>
						<div className='form-subsection'>
							<label htmlFor="brandurl">Brand Privacy Policy URL</label>
							<input
								type="url"
								id="brandurl"
								value={formData.brandurl}
								onChange={handleChange}
								placeholder="https://example.com/privacy-policy"
							/>
						</div>


						<div className='form-subsection'>
							<label htmlFor="brandsupportmail">Brand's Support Mail Address</label>
							<input
								type="email"
								id="brandsupportmail"
								value={formData.brandsupportmail}
								onChange={handleChange}
								placeholder="support@example.com"
							/>
						</div>

						<div className='form-subsection'>
							<label htmlFor="brandmail">Brand Mail</label>
							<input
								type="email"
								id="brandmail"
								value={formData.brandmail} // Read directly from formData
								onChange={handleChange}
							/>
						</div>

						<p className="error-message">{errorMessage}</p>

						<div className="form-step">3/4</div>

						<button type="submit">Next</button>
					</form>
				</div>

				<p className="signup-text">
					Already have an account?<a href="/login">Login</a>
				</p>
			</div>
			</div>
		</CSSTransition>
	);
}

export default SignupFormSection3;
