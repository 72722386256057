import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';

import AboutUs from './pages/AboutUs';
import CancellationAndRefund from "./pages/CancellationAndRefund"
import Error404 from "./pages/Error404"
import Home from "./pages/Home"
import Login from './pages/Login';
import Pricing from "./pages/Pricing"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import ShippingAndDelivery from "./pages/ShippingAndDelivery"
import Signup from "./pages/Signup";
import TermsAndCondition from "./pages/TermsAndConditions"

import "./styles/app.css"

function App() {

	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about-us" element={<AboutUs />} />
				<Route path="/cancellation-and-refund" element={<CancellationAndRefund />} />
				<Route path="/login" element={<Login />} />
				<Route path="/pricing" element={<Pricing />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/shipping-and-delivery" element={<ShippingAndDelivery />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/terms-and-conditions" element={<TermsAndCondition />} />
				<Route path="*" element={<Error404 />} />
			</Routes>
		</Router>
	);
}

export default App;
