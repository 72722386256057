import React from 'react';
import Image from '../../assets/job-application-template.png'

import MailHeader from './sub-components/MailHeader';
import SubjectLine from './sub-components/SubjectLine';
import SenderInfo from './sub-components/SenderInfo';

import './styles/mail-template.css'
import PhoneFrame from './PhoneFrame';

const JobApplicationTemplate = () => (
	<PhoneFrame>
		<MailHeader />
		<SubjectLine title="Congrats! You have a new job oppurtunity. Fill this application ASAP!" />
		<SenderInfo sender_initial="FJ" sender_name="FindJob.com" />
		<div className="emailprod">
			<img src={Image} alt="" />
		</div>
	</PhoneFrame>
);

export default JobApplicationTemplate;
