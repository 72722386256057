import React from 'react';
import './styles/footer.css';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png'
import Linkedin from '../assets/linkedin-logo.svg'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="logo-section">
                <img src={Logo} alt="Logo" className="footer-logo" />
                <p>&copy; 2024 The Bommber<br />All rights reserved.</p>
            </div>
            <div className="other-section">
                <div className="footer-pages">
                    <h4>Pages</h4>
                    <ul>
                        <li><Link style={{textDecoration:'none', color:'white'}}  to="/">Home</Link></li>
						<li><Link style={{textDecoration:'none', color:'white'}}  to="/about-us">About Us</Link></li>
                        <li><Link style={{textDecoration:'none', color:'white'}}  to="/pricing">Pricing</Link></li>
						<li><Link style={{textDecoration:'none', color:'white'}}  to="/login">Login</Link></li>
                        <li><Link style={{textDecoration:'none', color:'white'}}  to="/signup">Sign Up</Link></li>
                    </ul>
                </div>
                <div className="footer-pages">
                    <h4>Consumer Policy</h4>
                    <ul>
                        <li><Link style={{textDecoration:'none', color:'white'}} to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link style={{textDecoration:'none', color:'white'}} to="/terms-and-conditions">Terms and Conditions</Link></li>
                        <li><Link style={{textDecoration:'none', color:'white'}} to="/cancellation-and-refund">Cancellation and Refund</Link></li>
                        <li><Link style={{textDecoration:'none', color:'white'}} to="/shipping-and-delivery">Shipping and Delivery</Link></li>
                    </ul>
                </div>
                <div className="footer-pages">
                    <h4>Contact Us</h4>
                    <ul>
                        <li>abhishek@thebommbber.com</li>
                        <li>+91 9819099252</li>
                        <li>
							
							<a href="https://www.linkedin.com/company/thebommber/" target="_blank" rel="noreferrer">
								<div className="contact-socials">
									<img height={'40px'} src={Linkedin} alt="" />
									<p>LinkedIn</p>
								</div>
							</a>
						</li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
